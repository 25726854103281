import {CashierMandatoryFieldsOverrides} from '../../../types/app.types';
import {COUNTRIES_WITH_VAT, FORMS_TEMPLATE_IDS} from '../constants';
import {getContactFormInitialState, getContactFormOverrides} from './ContactForm/contactForm.utils';
import {getAddressFormInitialState, getAddressFormOverrides} from './AddressForm/addressForm.utils';
import {getVatFormInitialState} from './VatIdForm/VatForm.utils';
import {FormValues, validate} from '@wix/form-viewer';
import {applyOverrides as applyRuleOverrides} from '@wix/form-conditions';
import {MemberDetailsValidator} from '../MemberDetails/WithMemberDetailsData';
import type {FormOverrides} from '@wix/form-viewer/dist/types/services/form-overrides';

// eslint-disable-next-line @typescript-eslint/no-require-imports,@typescript-eslint/no-var-requires
const {applyNestedForms} = require('@wix/form-viewer/dist/esm/services/apply-nested-forms');
// eslint-disable-next-line @typescript-eslint/no-require-imports,@typescript-eslint/no-var-requires
const {applyFormOverrides} = require('@wix/form-viewer/dist/esm/services/form-overrides');

export const isBillingInfoValid: MemberDetailsValidator<CashierMandatoryFieldsOverrides> = async ({
  formsById,
  addressAndContact,
  checkoutSettings,
  context: cashierMandatoryFields,
}) => {
  const contactValidationPromise = validateFormWithOverrides({
    schema: formsById[FORMS_TEMPLATE_IDS.CONTACT],
    values: getContactFormInitialState({
      checkoutSettings,
      contact: addressAndContact.contact,
      country: addressAndContact.address?.country,
    }),
    overrides: getContactFormOverrides({checkoutSettings, overridePhone: cashierMandatoryFields?.phone}),
  });

  const addressValidationPromise = validateFormWithOverrides({
    schema: formsById[FORMS_TEMPLATE_IDS.ADDRESS],
    values: getAddressFormInitialState(checkoutSettings, addressAndContact.address),
    overrides: getAddressFormOverrides({checkoutSettings, overrides: cashierMandatoryFields}),
  });

  const validations = [contactValidationPromise, addressValidationPromise];

  if (addressAndContact.address?.country && COUNTRIES_WITH_VAT.includes(addressAndContact.address?.country)) {
    const vatValidationPromise = validateFormWithOverrides({
      schema: formsById[FORMS_TEMPLATE_IDS.VAT_ID],
      values: getVatFormInitialState(addressAndContact.contact),
      overrides: {},
    });
    validations.push(vatValidationPromise);
  }

  const validationErrors = await Promise.all(validations);
  const hasAnyErrors = validationErrors.some(
    (validationRes) => validationRes?.errors?.length && validationRes?.errors?.length > 0
  );

  return !hasAnyErrors;
};

function validateFormWithOverrides({
  schema,
  values,
  overrides,
}: {
  schema: ReturnType<typeof applyRuleOverrides>;
  values: FormValues;
  overrides: FormOverrides;
}) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const form = applyNestedForms(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    applyFormOverrides(applyRuleOverrides(schema, values), overrides)
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return validate(form, values);
}
