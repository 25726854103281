import {classes} from './DetailsFormButtons.st.css';
import {Button, ButtonPriority, ButtonSize, StatesButtonStates} from 'wix-ui-tpa';
import {NextStepButton} from '../NextStepButton/NextStepButton';
import React from 'react';
import {useMemberDetailsData} from '../../../../MemberDetails/WithMemberDetailsData';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';

export enum FormDetailsButtonsDataHook {
  continueButton = 'FormDetailsButtons.continue',
  cancelButton = 'FormDetailsButtons.cancel',
}

export const DetailsFormButtons = ({
  onContinue,
  continueButtonDisabled,
  continueButtonState,
}: {
  onContinue: () => void;
  continueButtonDisabled: boolean;
  continueButtonState: StatesButtonStates;
}) => {
  const localeKeys = useLocaleKeys();

  const {setEditMode, editMode} = useMemberDetailsData();

  return (
    <div className={classes.buttonsWrapper}>
      {editMode && (
        <Button
          data-hook={FormDetailsButtonsDataHook.cancelButton}
          priority={ButtonPriority.basicSecondary}
          size={ButtonSize.medium}
          className={classes.cancelButton}
          onClick={() => setEditMode(false)}
          upgrade>
          {localeKeys.checkout.cancel.cta()}
        </Button>
      )}
      <NextStepButton
        onClick={onContinue}
        text={editMode ? localeKeys.checkout.saveAndContinue.cta() : localeKeys.checkout.continue_button_label()}
        dataHook={FormDetailsButtonsDataHook.continueButton}
        disabled={continueButtonDisabled}
        buttonState={continueButtonState}
      />
    </div>
  );
};
