import React, {ReactNode} from 'react';
import {AddressDropdown} from './AddressDropdown/AddressDropdown';
import {MemberDetailsSummary} from './MemberDetailsSummary/MemberDetailsSummary';
import {classes} from './MemberDetails.st.css';
import {ADD_NEW_ADDRESS_ID} from '../constants';
import {MemberAddressNotValidError} from './MemberAddressNotValidError/MemberAddressNotValidError';
import {useMemberDetailsData} from './WithMemberDetailsData';

export enum MemberDetailsDataHook {
  root = 'MemberDetails.root',
  edit = 'MemberDetails.edit',
  new = 'MemberDetails.new',
}

export const MemberDetails = ({Form, dropdownLabel}: {Form: () => ReactNode; dropdownLabel: string}) => {
  const {areMemberDetailsValid, selectedAddressesServiceId, editMode} = useMemberDetailsData();

  return (
    <div>
      <div data-hook={MemberDetailsDataHook.root} className={classes.root}>
        <AddressDropdown dropdownLabel={dropdownLabel} />
        {selectedAddressesServiceId !== ADD_NEW_ADDRESS_ID && !editMode && (
          <>
            <MemberDetailsSummary />
            {!areMemberDetailsValid && <MemberAddressNotValidError />}
          </>
        )}
        {editMode && <div data-hook={MemberDetailsDataHook.edit}>{Form()}</div>}
        {selectedAddressesServiceId === ADD_NEW_ADDRESS_ID && (
          <div data-hook={MemberDetailsDataHook.new}>NEW (TEMPORARY)</div>
        )}
      </div>
    </div>
  );
};
